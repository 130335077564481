import { ENERGY_TYPES } from '../../../constants';

export const EVENT_ACTION_PROPERTY_NAME = 'eventDetails.action';
export const EVENT_CATEGORY_PROPERTY_NAME = 'eventDetails.category';

export const PAGE_CONTENT = {
    HOME: 'home',
    SOLAR: 'solar',
    PRODUCT_LIST: 'product list', // contract, producers pages
    PRODUCT_DETAILS: 'product detail',
    SIGN_UP: 'check-out',
    THANK_YOU: 'thank-you',
    OTHERS: 'corporate'
};

export const ENERGY_NAMES = {
    GAS: 'gas',
    ELECTRICITY: 'electricity',
    HEATING_ELECTRICITY: 'heizstrom',
    CHARGING_ELECTRICITY: 'ladestrom', // for cars
    SOLAR: 'solar',
    OTHERS: 'undefined'
};

export const PAGE_PRODUCT = {
    GAS: ENERGY_NAMES.GAS,
    ELECTRICITY: ENERGY_NAMES.ELECTRICITY,
    HEATING_ELECTRICITY: ENERGY_NAMES.HEATING_ELECTRICITY,
    CHARGING_ELECTRICITY: ENERGY_NAMES.CHARGING_ELECTRICITY,
    SOLAR: ENERGY_NAMES.SOLAR,
    OTHERS: 'corporate'
};

export const USER_PURCHASE = {
    GAS: ENERGY_NAMES.GAS,
    ELECTRICITY: ENERGY_NAMES.ELECTRICITY,
    HEATING_ELECTRICITY: ENERGY_NAMES.HEATING_ELECTRICITY,
    CHARGING_ELECTRICITY: ENERGY_NAMES.CHARGING_ELECTRICITY,
    SOLAR: ENERGY_NAMES.SOLAR,
    OTHERS: ENERGY_NAMES.OTHERS
};

export const SOCIAL_NETWORK_NAME = {
    FACEBOOK: 'facebook',
    MEDIUM: 'medium',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
    YOUTUBE: 'youtube',
    LINKEDIN: 'linkedin'
};

export const CATEGORY = {
    ELECTRICITY: ENERGY_TYPES.ELECTRICITY,
    GAS: ENERGY_TYPES.GAS,
    SEARCH_WIDGET: 'Search Widget',
    PRODUCT_LISTING: 'Product Listings',
    SOCIAL: 'Social',
    REFERRAL_CODE: 'Freundewerben',
    FAQ_SEARCH: 'Search FAQ'
};

export const SIGN_UP_STEP = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3
};

export const EVENT = {
    TRACK_EVENT: 'trackEvent',
    PRODUCT_IMPRESSION: 'productImpression',
    PRODUCT_DETAILS_PAGE: 'productDetail',
    PRODUCT_DETAILS_CLICK: 'productClick',
    SELECT_PRODUCT: 'addToCart',
    CHANGE_SIGN_UP_STEP: 'vpv',
    SIGN_UP_CHECKOUT: 'checkout',
    SIGN_UP_SUBMIT_TRANSACTION: 'transaction'
};

export const CURRENCY = 'EUR';

const QUANTITY = 1;

export const EVENT_DETAILS = {
    TARIFF_CALC_FORM_SUBMIT: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.SEARCH_WIDGET,
        ACTION: {
            ELECTRICITY: ENERGY_TYPES.ELECTRICITY,
            GAS: ENERGY_TYPES.GAS
        },
        LABEL: {
            TOP: 'top',
            BOTTOM: 'bottom'
        }
    },
    SHOW_MAP_CLICK: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.PRODUCT_LISTING,
        ACTION: 'Show map'
    },
    SORT_FILTER_CLICK: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.PRODUCT_LISTING,
        ACTION: 'Sort'
    },
    PRODUCT_LIST: {
        EVENT_NAME: EVENT.PRODUCT_IMPRESSION,
        CURRENCY,
        CATEGORY: {
            ELECTRICITY: CATEGORY.ELECTRICITY,
            GAS: CATEGORY.GAS
        }
    },
    PRODUCT_DETAILS_PAGE: {
        EVENT_NAME: EVENT.PRODUCT_DETAILS_PAGE,
        CURRENCY,
        CATEGORY: {
            ELECTRICITY: CATEGORY.ELECTRICITY,
            GAS: CATEGORY.GAS
        }
    },
    PRODUCT_DETAILS_CLICK: {
        EVENT_NAME: EVENT.PRODUCT_DETAILS_CLICK,
        CURRENCY,
        CATEGORY: {
            ELECTRICITY: CATEGORY.ELECTRICITY,
            GAS: CATEGORY.GAS
        }
    },
    SELECT_PRODUCT: {
        EVENT_NAME: EVENT.SELECT_PRODUCT,
        CURRENCY,
        QUANTITY
    },
    SOLAR_SIGNUP_STEP_SUBMIT: {
        EVENT_NAME: EVENT.CHANGE_SIGN_UP_STEP,
        PAGE_CONTENT: PAGE_CONTENT.SOLAR,
        PAGE_PRODUCT: PAGE_PRODUCT.SOLAR
    },
    SOCIAL_ICON_CLICK: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.SOCIAL
    },
    REFERRAL_CODE: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.REFERRAL_CODE
    },
    FAQ_SEARCH: {
        EVENT_NAME: EVENT.TRACK_EVENT,
        CATEGORY: CATEGORY.FAQ_SEARCH
    },
    MAIN_SIGNUP_STEP_SUBMIT: {
        EVENT_NAME: EVENT.CHANGE_SIGN_UP_STEP,
        PAGE_CONTENT: PAGE_CONTENT.SIGN_UP
    },
    MAIN_SIGNUP_STEP_CHECKOUT: {
        EVENT_NAME: EVENT.SIGN_UP_CHECKOUT,
        CURRENCY,
        QUANTITY
    },
    MAIN_SIGNUP_SUBMIT_TRANSACTION: {
        EVENT_NAME: EVENT.SIGN_UP_SUBMIT_TRANSACTION,
        CURRENCY,
        QUANTITY,
        TAX: '1.99'
    }
};
