export const LOCAL_STORAGE_KEYS = {
    accessPassword: 'accessPassword',
    cookiesAgreement: 'stromee-cookie-agreement',
    utmSource: 'stromee-utm_source',
    utmCampaign: 'stromee-utm_campaign',
    utmData: 'stromee-utm_data'
};

const WARNING_MESSAGE =
    '"localStorage" is blocked or not supported. Please check your browser settings, or contact us.';

export function getDataFromLocalStorage(key) {
    if (!process.browser || !key) {
        return null;
    }

    try {
        return window.localStorage.getItem(key);
    } catch (error) {
        console.warn(WARNING_MESSAGE, error);
        return null;
    }
}

export function setDataToLocalStorage(key, value) {
    if (!process.browser || !key) {
        return;
    }

    try {
        window.localStorage.setItem(key, value);
    } catch (error) {
        console.warn(WARNING_MESSAGE, error);
    }
}

const asyncLocalStorage = {
    setItem: (key, value) => {
        if (!process.browser) {
            return;
        }
        Promise.resolve().then(() => setDataToLocalStorage(key, value));
    },
    getItem: key => {
        if (!process.browser) {
            return null;
        }
        return Promise.resolve().then(() => getDataFromLocalStorage(key));
    }
};

function setAccessPasswordAsync(value) {
    asyncLocalStorage.setItem(LOCAL_STORAGE_KEYS.accessPassword, value);
}

function getAccessPassword() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.accessPassword);
}

function setCookiesAgreementKey(value) {
    setDataToLocalStorage(LOCAL_STORAGE_KEYS.cookiesAgreement, value);
}

function getCookiesAgreementKey() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.cookiesAgreement);
}

function setUtmSource(value) {
    setDataToLocalStorage(LOCAL_STORAGE_KEYS.utmSource, value);
}

function getUtmSource() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.utmSource);
}

function setUtmCampaign(value) {
    setDataToLocalStorage(LOCAL_STORAGE_KEYS.utmCampaign, value);
}

function getUtmCampaign() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.utmCampaign);
}

function setUtmData(value) {
    setDataToLocalStorage(LOCAL_STORAGE_KEYS.utmData, value);
}

function getUtmData() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.utmData);
}

const LocalStorage = {
    setAccessPasswordAsync,
    getAccessPassword,
    setCookiesAgreementKey,
    getCookiesAgreementKey,
    setUtmSource,
    getUtmSource,
    setUtmCampaign,
    getUtmCampaign,
    setUtmData,
    getUtmData
};

export default LocalStorage;
