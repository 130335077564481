import { APP_ENV, USER_AGENTS } from '../../constants';

export function isPuppeteerEnv() {
    return process.browser && window.navigator.userAgent === USER_AGENTS.E2E;
}

export function isTestEnv() {
    return process.env.APP_ENV === APP_ENV.TEST;
}

export function isStagingEnv() {
    return process.env.APP_ENV === APP_ENV.STAGING;
}

export function isProdEnv() {
    return process.env.APP_ENV === APP_ENV.PRODUCTION;
}

export function isProdAndNotPuppeteerEnv() {
    return !isPuppeteerEnv() && isProdEnv();
}

export function isDevEnv() {
    return process.env.APP_ENV === APP_ENV.DEV;
}

// TODO: hotjar will be removed after Florian approve. @Anton
export function isShouldLoadHotjarScript() {
    return (isProdAndNotPuppeteerEnv() || isTestEnv()) && process.env.IS_SHOULD_LOAD_HOTJAR_SCRIPT === 'true';
}
