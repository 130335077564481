import React from 'react';
import { DEFAULT_ENERGY_TYPE, DEFAULT_ZIP, GTM_ID, PAGE_ID } from '../../../constants';
import {
    EVENT_DETAILS,
    PAGE_CONTENT,
    SIGN_UP_STEP,
    USER_PURCHASE,
    EVENT_ACTION_PROPERTY_NAME,
    EVENT_CATEGORY_PROPERTY_NAME
} from './constants';
import {
    correctValueForGA,
    pushToDL,
    getPageProductValue,
    getProductContentValue,
    getUserPurchaseValue,
    extractAnnualProductPrice,
    getSocialNetworkNameByUrl,
    getPartnerName,
    formatTextToGTM,
    prepareCommonProductDataForDL
} from './helpers';

import {
    isSolarPage,
    isProducersPage,
    isProducerDetailsPage,
    isContractPage,
    isSignUpPage,
    isThankYouPage
} from '../url/url';

import { isTestEnv } from '../env';

export function generateDataLayer({
    query: { zip = DEFAULT_ZIP, energyType = DEFAULT_ENERGY_TYPE, energySubTypeId } = {},
    pageUrl = '',
    landingPageDetails: { businessChannel, name } = {}
} = {}) {
    const partnerName = getPartnerName(businessChannel, name);

    const dataLayer = {
        pageContent: getProductContentValue(pageUrl), // static value
        businessChannel: correctValueForGA(businessChannel), // see excel for values, = businessChannel
        partnerName,
        pageProduct: getPageProductValue({ energyType, pageUrl, energySubTypeId })
    };

    if (
        isProducersPage(pageUrl) ||
        isProducerDetailsPage(pageUrl) ||
        isContractPage(pageUrl) ||
        isSignUpPage(pageUrl)
    ) {
        dataLayer.zipCode = correctValueForGA(zip);
    }

    if (isThankYouPage(pageUrl)) {
        dataLayer.userPurchase = getUserPurchaseValue(energyType, energySubTypeId);
        dataLayer.pageName = `/${PAGE_ID.SIGN_UP}/${partnerName}/thank-you`;
    }

    if (isSolarPage(pageUrl)) {
        dataLayer.pageName = `/${PAGE_CONTENT.SOLAR}`;
    }

    if (isSignUpPage(pageUrl)) {
        // ex /signup/standard/step1 or /signup/terra-aqua/step1
        dataLayer.pageName = `/${PAGE_ID.SIGN_UP}/${partnerName}/step1`;
    }

    if (!isTestEnv()) {
        console.info('Generated the data for GTM dataLayer:', JSON.stringify(dataLayer), {
            dataLayer,
            methodName: 'generateDataLayer'
        });
    }

    return dataLayer;
}

export function addSubmitTariffCalcDataToDL({ energyType = DEFAULT_ENERGY_TYPE, place }) {
    const dataLayer = {
        event: EVENT_DETAILS.TARIFF_CALC_FORM_SUBMIT.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.TARIFF_CALC_FORM_SUBMIT.CATEGORY, // static
        [EVENT_ACTION_PROPERTY_NAME]: correctValueForGA(energyType), // values: electricity, gas
        // values: top if top of page widget, bottom if bottom page widget.
        'eventDetails.label': correctValueForGA(place)
    };

    pushToDL(dataLayer, 'addSubmitTariffCalcDataToDL');
}

export function addProductListDetailsToDL(products = [], landingPageDetails = {}, energyType = DEFAULT_ENERGY_TYPE) {
    const { businessChannel, name } = landingPageDetails;

    // TODO: Handle case when more 50 (R)
    const firstProducts = products.slice(0, 50);

    const impressions = firstProducts.map((product = {}) => ({
        // selected tariff or selected partner;
        // ensure no punctuation or special characters check with RL - tariffName OR producer name
        name: formatTextToGTM(correctValueForGA(product.name)),
        id: String(product.id), // same as 'name' or partner/tariff ID if applicable
        price: extractAnnualProductPrice(product), // annual tariff (no commas)
        brand: correctValueForGA(businessChannel), // = businessChannel
        category: correctValueForGA(energyType), // electricity, gas
        list: getPartnerName(businessChannel, name)
    }));

    const dataLayer = {
        event: EVENT_DETAILS.PRODUCT_LIST.EVENT_NAME,
        ecommerce: {
            currencyCode: EVENT_DETAILS.PRODUCT_LIST.CURRENCY,
            impressions
        }
    };

    pushToDL(dataLayer, 'addProductListDetailsToDL');
}

export function addProductDetailsPageDataToDL(product = {}, landingPageDetails = {}, energyType = DEFAULT_ENERGY_TYPE) {
    const { businessChannel, name } = landingPageDetails;

    const productForDL = {
        ...prepareCommonProductDataForDL(product, businessChannel, energyType),
        dimension1: getPartnerName(businessChannel, name)
    };

    const dataLayer = {
        event: EVENT_DETAILS.PRODUCT_DETAILS_PAGE.EVENT_NAME,
        ecommerce: {
            currencyCode: EVENT_DETAILS.PRODUCT_DETAILS_PAGE.CURRENCY,
            detail: {
                products: [productForDL]
            }
        }
    };

    pushToDL(dataLayer, 'addProductDetailsPageDataToDL');
}

export function addProductDetailsClickDataToDL(
    product = {},
    landingPageDetails = {},
    energyType = DEFAULT_ENERGY_TYPE
) {
    const { businessChannel, name } = landingPageDetails;

    const partnerName = getPartnerName(businessChannel, name);
    const productForDL = {
        ...prepareCommonProductDataForDL(product, businessChannel, energyType),
        list: partnerName
    };

    const dataLayer = {
        event: EVENT_DETAILS.PRODUCT_DETAILS_CLICK.EVENT_NAME,
        ecommerce: {
            currencyCode: EVENT_DETAILS.PRODUCT_DETAILS_CLICK.CURRENCY,
            click: {
                actionField: { list: partnerName },
                products: [productForDL]
            }
        }
    };

    pushToDL(dataLayer, 'addProductDetailsClickDataToDL');
}

export function addSelectedProductDataToDL(product = {}, landingPageDetails = {}, energyType = DEFAULT_ENERGY_TYPE) {
    const { businessChannel, name } = landingPageDetails;

    const productForDL = {
        ...prepareCommonProductDataForDL(product, businessChannel, energyType),
        dimension1: getPartnerName(businessChannel, name),
        quantity: EVENT_DETAILS.SELECT_PRODUCT.QUANTITY, //  static
        metric1: extractAnnualProductPrice(product) // annual price
    };

    const dataLayer = {
        event: EVENT_DETAILS.SELECT_PRODUCT.EVENT_NAME,
        ecommerce: {
            currencyCode: EVENT_DETAILS.SELECT_PRODUCT.CURRENCY,
            add: {
                products: [productForDL]
            }
        }
    };

    pushToDL(dataLayer, 'addSelectedProductDataToDL');
}

export function addShowMapClickDataToDL() {
    const dataLayer = {
        event: EVENT_DETAILS.SHOW_MAP_CLICK.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.SHOW_MAP_CLICK.CATEGORY, // static
        [EVENT_ACTION_PROPERTY_NAME]: EVENT_DETAILS.SHOW_MAP_CLICK.ACTION // static
    };

    pushToDL(dataLayer);
}

export function addSortFilterClickDataToDL({ label }) {
    const dataLayer = {
        event: EVENT_DETAILS.SORT_FILTER_CLICK.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.SORT_FILTER_CLICK.CATEGORY, // static
        [EVENT_ACTION_PROPERTY_NAME]: EVENT_DETAILS.SORT_FILTER_CLICK.ACTION, // static
        'eventDetails.label': label // price or distance
    };

    pushToDL(dataLayer, 'addSortFilterClickDataToDL');
}

export function addSolarFormSubmitDataToDL(landingPageDetails = {}, step) {
    const { businessChannel, name } = landingPageDetails;

    const dataLayer = {
        event: EVENT_DETAILS.SOLAR_SIGNUP_STEP_SUBMIT.EVENT_NAME,
        pageContent: EVENT_DETAILS.SOLAR_SIGNUP_STEP_SUBMIT.PAGE_CONTENT, // static value
        partnerName: getPartnerName(businessChannel, name),
        businessChannel: correctValueForGA(businessChannel), // see excel for values ex only
        pageProduct: EVENT_DETAILS.SOLAR_SIGNUP_STEP_SUBMIT.PAGE_PRODUCT, // static
        pageName: `/${PAGE_CONTENT.SOLAR}/step${step}`
    };

    if (step === SIGN_UP_STEP.THIRD) {
        dataLayer.pageName = `/${PAGE_CONTENT.SOLAR}/thankyou`;
        dataLayer.userPurchase = USER_PURCHASE.SOLAR;
    }

    pushToDL(dataLayer);
}

export function addSocialIconClickDataToDL(action) {
    const dataLayer = {
        event: EVENT_DETAILS.SOCIAL_ICON_CLICK.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.SOCIAL_ICON_CLICK.CATEGORY, // static
        // values: name of social network, ie facebook, medium, instagram, etc.
        [EVENT_ACTION_PROPERTY_NAME]: getSocialNetworkNameByUrl(action)
    };

    pushToDL(dataLayer, 'addSocialIconClickDataToDL');
}

export function addGeneratedReferralCodeDataToDL(code) {
    const dataLayer = {
        event: EVENT_DETAILS.REFERRAL_CODE.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.REFERRAL_CODE.CATEGORY, // static
        [EVENT_ACTION_PROPERTY_NAME]: correctValueForGA(code) // code generated
    };

    pushToDL(dataLayer);
}

export function addFAQSearchDataToDL(searchValue) {
    const dataLayer = {
        event: EVENT_DETAILS.FAQ_SEARCH.EVENT_NAME,
        [EVENT_CATEGORY_PROPERTY_NAME]: EVENT_DETAILS.FAQ_SEARCH.CATEGORY, // static
        [EVENT_ACTION_PROPERTY_NAME]: correctValueForGA(searchValue) // search term used
    };

    pushToDL(dataLayer, 'addFAQSearchDataToDL');
}

export function addMainSignUpStepDataToDL({
    product = {},
    landingPageDetails = {},
    energyType = DEFAULT_ENERGY_TYPE,
    step,
    isLastStep,
    energySubTypeId,
    pageUrl = ''
}) {
    const { businessChannel, name } = landingPageDetails;

    const partnerName = getPartnerName(businessChannel, name);
    const dataLayer1 = {
        event: EVENT_DETAILS.MAIN_SIGNUP_STEP_SUBMIT.EVENT_NAME,
        pageContent: EVENT_DETAILS.MAIN_SIGNUP_STEP_SUBMIT.PAGE_CONTENT, // static value
        partnerName,
        businessChannel: correctValueForGA(businessChannel), // see excel for values ex only
        // energyType values: electricity or gas
        pageProduct: getPageProductValue({ energyType, pageUrl, energySubTypeId }),
        // ex /signup/standard/step2 or /signup/terra-aqua/step2
        pageName: `/${PAGE_ID.SIGN_UP}/${partnerName}/step${step + 1}`
    };

    const productForDL = {
        ...prepareCommonProductDataForDL(product, businessChannel, energyType),
        dimension1: partnerName,
        quantity: EVENT_DETAILS.MAIN_SIGNUP_STEP_CHECKOUT.QUANTITY //  static, note no ''
    };

    const dataLayer2 = {
        event: EVENT_DETAILS.MAIN_SIGNUP_STEP_CHECKOUT.EVENT_NAME,
        ecommerce: {
            currencyCode: EVENT_DETAILS.MAIN_SIGNUP_STEP_CHECKOUT.CURRENCY,
            checkout: {
                actionField: { step }
            }
        }
    };

    if (step === SIGN_UP_STEP.FIRST) {
        dataLayer2.ecommerce.checkout.products = [productForDL];
    }

    const methodName = 'addMainSignUpStepDataToDL';
    if (!isLastStep) {
        pushToDL(dataLayer1, methodName);
    }

    pushToDL(dataLayer2, methodName);
}

export function addMainSignUpSubmitDataToDL(
    product = {},
    landingPageDetails = {},
    { txId, energyType = DEFAULT_ENERGY_TYPE, referralCode }
) {
    const { businessChannel, name } = landingPageDetails;

    const productForDL = {
        ...prepareCommonProductDataForDL(product, businessChannel, energyType),
        dimension1: getPartnerName(businessChannel, name),
        quantity: EVENT_DETAILS.MAIN_SIGNUP_SUBMIT_TRANSACTION.QUANTITY //  static, note no ''
    };

    const dataLayer = {
        event: EVENT_DETAILS.MAIN_SIGNUP_SUBMIT_TRANSACTION.EVENT_NAME, // static
        transactionId: correctValueForGA(txId), // transaction ID
        transactionTotal: extractAnnualProductPrice(product), // total annual tariff
        // coupon/bonuscode if used, if not leave empty (here the referral code or individual code)
        transactionCoupon: correctValueForGA(referralCode),
        ecommerce: {
            currencyCode: EVENT_DETAILS.MAIN_SIGNUP_SUBMIT_TRANSACTION.CURRENCY,
            purchase: {
                actionField: {
                    id: correctValueForGA(txId), // transaction ID
                    revenue: extractAnnualProductPrice(product), // total annual tariff
                    tax: EVENT_DETAILS.MAIN_SIGNUP_SUBMIT_TRANSACTION.TAX // order tax value
                },
                products: [productForDL]
            }
        }
    };

    pushToDL(dataLayer, 'addMainSignUpSubmitDataToDL');
}

export function getGTMScript(pageUrl, query, landingPageDetails) {
    const dataLayerData = generateDataLayer({ pageUrl, query, landingPageDetails });

    const dataLayerPush = Object.keys(dataLayerData).length ?
        `window.dataLayer.push(${JSON.stringify(dataLayerData)});` :
        '';

    const googleTagManager = `
            window.dataLayer = window.dataLayer || [];
            ${dataLayerPush}

            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0];
                var j = d.createElement(s);
                var dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${GTM_ID}');
        `;

    const googleTagManagerNoScript = (
        <noscript>
            <iframe
                title="googletagmanager"
                src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
            />
        </noscript>
    );

    return {
        GTMHeadTag: GTM_ID ? <script dangerouslySetInnerHTML={{ __html: googleTagManager }} /> : null,
        GTMBodyTag: GTM_ID ? googleTagManagerNoScript : null
    };
}
