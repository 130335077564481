export const SESSION_STORAGE_KEYS = {
    invitedBy: 'stromee-invited-by',
    salesPartnerId: 'stromee-code'
};

export const WARNING_MESSAGE =
    '"sessionStorage" is blocked or not supported. Please check your browser settings, or contact us.';

export function getDataFromSessionStorage(key) {
    if (!process.browser || !key) {
        return null;
    }

    try {
        return window.sessionStorage.getItem(key);
    } catch (error) {
        console.warn(WARNING_MESSAGE, error);
        return null;
    }
}

export function setDataToSessionStorage(key, value) {
    if (!process.browser || !key || !value) {
        return;
    }

    try {
        window.sessionStorage.setItem(key, value);
    } catch (error) {
        console.warn(WARNING_MESSAGE, error);
    }
}

function setInvitedByInfo(value) {
    setDataToSessionStorage(SESSION_STORAGE_KEYS.invitedBy, value);
}

function getInvitedByInfo() {
    return getDataFromSessionStorage(SESSION_STORAGE_KEYS.invitedBy);
}

function setSalesPartnerId(value) {
    setDataToSessionStorage(SESSION_STORAGE_KEYS.salesPartnerId, value);
}

function getSalesPartnerId() {
    return getDataFromSessionStorage(SESSION_STORAGE_KEYS.salesPartnerId);
}

const SessionStorage = {
    setInvitedByInfo,
    getInvitedByInfo,
    getSalesPartnerId,
    setSalesPartnerId
};

export default SessionStorage;
