import isEmpty from 'lodash/isEmpty';
import LocalStorage from '../../localStorage';
import SessionStorage from '../../sessionStorage';

import {
    SOLAR_PAGE_PATH,
    PRODUCERS_PAGE_PATH,
    PRODUCER_DETAILS_PAGE_PATH,
    CONTRACT_PAGE_PATH,
    SIGN_UP_PAGE_PATH,
    THANK_YOU_PAGE_PATH,
    HOME_PAGE_PATH,
    DEFAULT_ZIP,
    DEFAULT_CONSUMPTION,
    DEFAULT_ENERGY_TYPE,
    DEFAULT_ORDER_BY,
    DEFAULT_SORT_DIRECTION,
    DEFAULT_PAGE_ID,
    DEFAULT_SIGN_UP_FLOW,
    DEFAULT_MARKETPLACE_ID,
    DEFAULT_FILTER_BY
} from '../../../constants';

// TODO: Refactoring, find better solution (R)
export function getSearchParamValueFromURL(locationSearch = undefined, key = undefined) {
    if (typeof locationSearch !== 'string' || typeof key !== 'string') {
        return undefined;
    }

    const searchParams = locationSearch.split('&');
    const urlSearchParam = searchParams.filter(el => el.includes(key));

    if (isEmpty(urlSearchParam)) {
        return undefined;
    }

    const searchParam = urlSearchParam[0].split('=');

    if (searchParam.length !== 2 || searchParam[0] !== key) {
        return undefined;
    }

    return searchParam[1];
}

export function getPagePathWithoutQueryParams(path = '') {
    return path.split('?')[0];
}

export const isHomePage = (pageUrl = '') => {
    const [pagePath] = pageUrl.split('?');
    return pagePath === HOME_PAGE_PATH;
};

export const isSolarPage = pageUrl => pageUrl.includes(SOLAR_PAGE_PATH);
export const isProducersPage = pageUrl => pageUrl.includes(PRODUCERS_PAGE_PATH);
export const isProducerDetailsPage = pageUrl => pageUrl.includes(PRODUCER_DETAILS_PAGE_PATH);
export const isContractPage = pageUrl => pageUrl.includes(CONTRACT_PAGE_PATH);
export const isSignUpPage = pageUrl => pageUrl.includes(SIGN_UP_PAGE_PATH);
export const isThankYouPage = pageUrl => pageUrl.includes(THANK_YOU_PAGE_PATH);

export const pickQueryParamsWithDefaults = (query = {}) => ({
    ...query,
    zip: query.zip ?? DEFAULT_ZIP,
    consumption: query.consumption ?? DEFAULT_CONSUMPTION,
    energyType: query.energyType ?? DEFAULT_ENERGY_TYPE,
    orderBy: query.orderBy ?? DEFAULT_ORDER_BY,
    ascDesc: query.ascDesc ?? DEFAULT_SORT_DIRECTION,
    identifier: query.identifier ?? DEFAULT_PAGE_ID,
    signupFlow: query.signupFlow ?? DEFAULT_SIGN_UP_FLOW,
    marketplaceId: query.marketplaceId ?? DEFAULT_MARKETPLACE_ID,
    filterBy: query.filterBy ?? DEFAULT_FILTER_BY
});

export function replaceGermanLetters(text = '') {
    if (typeof text !== 'string') {
        return text;
    }

    return text.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss');
}

export function formatTextToURL(text) {
    if (typeof text !== 'string') {
        return text;
    }

    const formattedText = replaceGermanLetters(text.toLowerCase())
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/ /g, '-');

    return formattedText;
}

export function saveQueryDataToStorage() {
    try {
        const locationSearch = window.location.search.substring(1);

        const utmSource = getSearchParamValueFromURL(locationSearch, 'utm_source');
        const utmCampaign = getSearchParamValueFromURL(locationSearch, 'utm_campaign');
        const utmData = getSearchParamValueFromURL(locationSearch, 'utm_data');
        const code = getSearchParamValueFromURL(locationSearch, 'code');
        const invitedBy = getSearchParamValueFromURL(locationSearch, 'ref');

        if (utmSource) {
            LocalStorage.setUtmSource(utmSource);
        }

        if (utmCampaign) {
            LocalStorage.setUtmCampaign(utmCampaign);
        }

        if (utmData) {
            LocalStorage.setUtmData(utmData);
        }

        if (code) {
            SessionStorage.setSalesPartnerId(code);
        }

        if (invitedBy) {
            SessionStorage.setInvitedByInfo(invitedBy);
        }
    } catch (error) {
        console.warn(
            '"sessionStorage" or "localStorage" is blocked or not supported. Please check your browser settings, or contact us.',
            error
        );
    }
}

export function pickProducerIdFromQueryParam(producerIdWithName = '') {
    if (!producerIdWithName) {
        return null;
    }

    const [producerId] = producerIdWithName.split('-');

    if (Number.isNaN(Number(producerId))) {
        return null;
    }

    return producerId;
}
