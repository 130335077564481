import { PAGE_CONTENT, PAGE_PRODUCT, SOCIAL_NETWORK_NAME, USER_PURCHASE, ENERGY_NAMES } from './constants';

import {
    ENERGY_TYPES,
    MOBILITY_ENERGY_SUB_TYPE_IDS,
    STANDARD_STROMEE_BUSINESS_CHANNEL,
    HEAT_ENERGY_SUB_TYPE_IDS,
    DEFAULT_ENERGY_TYPE
} from '../../../constants';

import { isTestEnv } from '../env';

import {
    isSolarPage,
    isProducersPage,
    isProducerDetailsPage,
    isContractPage,
    isSignUpPage,
    isThankYouPage,
    isHomePage,
    replaceGermanLetters
} from '../url/url';

export const correctValueForGA = v => (!v ? 'undefined' : v);

export const createEmptyDataLayerIfNeed = () => (window.dataLayer = window.dataLayer || []);

export const pushToDL = (dataLayer, methodName) => {
    createEmptyDataLayerIfNeed();

    if (!isTestEnv()) {
        // console.info('Just pushed the data to GTM dataLayer:', JSON.stringify(dataLayer), { dataLayer, methodName });
    }

    // window.dataLayer.push(dataLayer);
};

export function getEnergyNameByEnergySubTypeId(energySubTypeId) {
    if (
        [
            MOBILITY_ENERGY_SUB_TYPE_IDS.mobility,
            MOBILITY_ENERGY_SUB_TYPE_IDS.home,
            MOBILITY_ENERGY_SUB_TYPE_IDS.double
        ].includes(Number(energySubTypeId))
    ) {
        return ENERGY_NAMES.CHARGING_ELECTRICITY;
    }

    if (
        [
            HEAT_ENERGY_SUB_TYPE_IDS.pump.single,
            HEAT_ENERGY_SUB_TYPE_IDS.pump.double,
            HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.single,
            HEAT_ENERGY_SUB_TYPE_IDS.nightStorageHeating.double,
            HEAT_ENERGY_SUB_TYPE_IDS.doubleTariff
        ].includes(Number(energySubTypeId))
    ) {
        return ENERGY_NAMES.HEATING_ELECTRICITY;
    }

    return ENERGY_NAMES.OTHERS;
}

export function getProductContentValue(pageUrl = '') {
    if (isSolarPage(pageUrl)) {
        return PAGE_CONTENT.SOLAR;
    }
    if (isProducersPage(pageUrl) || isContractPage(pageUrl)) {
        return PAGE_CONTENT.PRODUCT_LIST;
    }
    if (isProducerDetailsPage(pageUrl)) {
        return PAGE_CONTENT.PRODUCT_DETAILS;
    }
    if (isSignUpPage(pageUrl)) {
        return PAGE_CONTENT.SIGN_UP;
    }
    if (isThankYouPage(pageUrl)) {
        return PAGE_CONTENT.THANK_YOU;
    }
    if (isHomePage(pageUrl)) {
        return PAGE_CONTENT.HOME;
    }

    return PAGE_CONTENT.OTHERS;
}

export function getPageProductValue({ energyType = DEFAULT_ENERGY_TYPE, pageUrl = '', energySubTypeId }) {
    if (isSolarPage(pageUrl)) {
        return PAGE_PRODUCT.SOLAR;
    }

    if (
        !isContractPage(pageUrl) &&
        !isProducersPage(pageUrl) &&
        !isSignUpPage(pageUrl) &&
        !isThankYouPage(pageUrl) &&
        !isProducerDetailsPage(pageUrl)
    ) {
        return PAGE_PRODUCT.OTHERS;
    }

    if (energySubTypeId) {
        return getEnergyNameByEnergySubTypeId(energySubTypeId);
    }

    if (energyType === ENERGY_TYPES.GAS) {
        return PAGE_PRODUCT.GAS;
    }

    if (energyType === ENERGY_TYPES.ELECTRICITY) {
        return PAGE_PRODUCT.ELECTRICITY;
    }

    return PAGE_PRODUCT.OTHERS;
}

export function getUserPurchaseValue(energyType = DEFAULT_ENERGY_TYPE, energySubTypeId) {
    //  on the “/thankyou“ pages and on the “/solar“ last step

    if (energySubTypeId) {
        return getEnergyNameByEnergySubTypeId(energySubTypeId);
    }

    if (energyType === ENERGY_TYPES.GAS) {
        return USER_PURCHASE.GAS;
    }

    if (energyType === ENERGY_TYPES.ELECTRICITY) {
        return USER_PURCHASE.ELECTRICITY;
    }

    return USER_PURCHASE.OTHERS;
}

export function extractAnnualProductPrice(product = {}) {
    const tariffMonthlyPrice = product?.data?.monthlyPrice;
    const producerMonthlyPrice = product.generalMonthlyPrice;
    const productMonthlyPrice = producerMonthlyPrice || tariffMonthlyPrice || null;
    const yearlyProductPrice = productMonthlyPrice * 12;

    // To round an yearlyProductPrice to the 2nd digit after the decimal
    return String(Math.round(yearlyProductPrice * 100) / 100);
}

export function getPartnerName(businessChannel, pageId) {
    // if businessChannel === "stromee" we send the "stromee" to partnerName field to dataLayer.
    // if businessChannel !== "stromee" we send the "page name" to partnerName field to dataLayer.
    return correctValueForGA(businessChannel === STANDARD_STROMEE_BUSINESS_CHANNEL ? businessChannel : pageId);
}

export function getSocialNetworkNameByUrl(url = '') {
    if (url.includes(SOCIAL_NETWORK_NAME.INSTAGRAM)) {
        return SOCIAL_NETWORK_NAME.INSTAGRAM;
    }
    if (url.includes(SOCIAL_NETWORK_NAME.FACEBOOK)) {
        return SOCIAL_NETWORK_NAME.FACEBOOK;
    }
    if (url.includes(SOCIAL_NETWORK_NAME.LINKEDIN)) {
        return SOCIAL_NETWORK_NAME.LINKEDIN;
    }
    if (url.includes(SOCIAL_NETWORK_NAME.MEDIUM)) {
        return SOCIAL_NETWORK_NAME.MEDIUM;
    }
    if (url.includes(SOCIAL_NETWORK_NAME.TWITTER)) {
        return SOCIAL_NETWORK_NAME.TWITTER;
    }
    if (url.includes(SOCIAL_NETWORK_NAME.YOUTUBE)) {
        return SOCIAL_NETWORK_NAME.YOUTUBE;
    }
    return undefined;
}

export function formatTextToGTM(text) {
    if (typeof text !== 'string') {
        return text;
    }

    const formattedText = replaceGermanLetters(text.toLowerCase()).replace(/[^a-zA-Z0-9 ]/g, '');

    return formattedText;
}

export const prepareCommonProductDataForDL = (product, businessChannel, energyType) => ({
    // selected tariff or selected partner; ensure no punctuation or special characters
    name: formatTextToGTM(correctValueForGA(product.name)),
    id: String(product.id), // same as 'name' or partner/tariff ID if applicable
    price: extractAnnualProductPrice(product), // annual tariff (no commas)
    brand: correctValueForGA(businessChannel), // =businessChannel
    category: correctValueForGA(energyType) // electricity, gas
});
